import './App.css';
// import SimpleGallery from './Gallery';

function App() {
  return (
    <div className="App">
       {/* <SimpleGallery
          galleryID="my-test-gallery"
          images={[
            {
              largeURL:
                './1.png',
              thumbnailURL:
                './thumbnail-1.png',
              width: 2500,
              height: 1300,
            }
          ]}
        /> */}
        <img src="./1.png" alt="" width={400}/>
    </div>
  );
}

export default App;
